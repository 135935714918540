/* Red */

$palette-red-50: #FFEBEE;
$palette-red-100: #FFCDD2;
$palette-red-200: #EF9A9A;
$palette-red-300: #E57373;
$palette-red-400: #EF5350;
$palette-red-500: #F44336;
$palette-red-600: #E53935;
$palette-red-700: #D32F2F;
$palette-red-800: #C62828;
$palette-red-900: #B71C1C;

$palette-red-A100: #FF8A80;
$palette-red-A200: #FF5252;
$palette-red-A400: #FF1744;
$palette-red-A700: #D50000;

/* Gray */

$palette-gray-50: #FAFAFA;
$palette-gray-100: #F5F5F5;
$palette-gray-200: #EEEEEE;
$palette-gray-300: #E0E0E0;
$palette-gray-400: #BDBDBD;
$palette-gray-500: #9E9E9E;
$palette-gray-600: #757575;
$palette-gray-700: #616161;
$palette-gray-800: #424242;
$palette-gray-900: #212121;

/* Cyan */

$palette-cyan-50: #d6faf9;
$palette-cyan-100: #95f3f1;
$palette-cyan-200: #00eaea;
$palette-cyan-300: #00dee3;
$palette-cyan-400: #00d5dd;
$palette-cyan-500: #00cbda;
$palette-cyan-600: #00bbc7;
$palette-cyan-700: #00a5ac;
$palette-cyan-800: #009093;
$palette-cyan-900: #006c64;
